<template>
  <div class="analysis">
    <!-- <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>
    </div> -->

    <div class="df-row data-table">
      <span class="title">会员状态</span>

      <div class="chart-table">
        <div class="chart-box">
          <div class="chart-item">
            <div class="content">
              <span class="value">{{ stageGroup.Total }}</span>
              <div class="info">
                <!-- <span class="icon"></span> -->
                <span class="name">总顾客</span>
              </div>
            </div>

            <div class="dfWebIcon dfWebIcon-dengyu"></div>
          </div>

          <div
            class="chart-item"
            v-for="(item, index) in stageGroup.Items"
            :key="index"
          >
            <div class="content">
              <span class="value" :style="'color:' + colorList[index]">{{
                item.Count
              }}</span>
              <div class="info">
                <!-- <span class="icon"></span> -->
                <span class="name">{{ item.Label }}</span>
              </div>
            </div>

            <div class="dfWebIcon dfWebIcon-jia"></div>
          </div>
        </div>

        <div id="Stage" class="chart"></div>
      </div>
    </div>

    <div class="df-row data-table">
      <span class="title">会员忠诚度</span>

      <div class="chart-table">
        <div class="chart-box">
          <div class="chart-item">
            <div class="content">
              <span class="value">{{ lossGroup.Total }}</span>
              <div class="info">
                <!-- <span class="icon"></span> -->
                <span class="name">总数</span>
              </div>
            </div>

            <div class="dfWebIcon dfWebIcon-dengyu"></div>
          </div>

          <div
            class="chart-item"
            v-for="(item, index) in lossGroup.Items"
            :key="index"
          >
            <div class="content">
              <span class="value" :style="'color:' + colorList[index]">{{
                item.Count
              }}</span>
              <div class="info">
                <!-- <span class="icon"></span> -->
                <span class="name">{{ item.Label }}</span>
              </div>
            </div>

            <div class="dfWebIcon dfWebIcon-jia"></div>
          </div>
        </div>

        <div id="Loss" class="chart"></div>
      </div>
    </div>

    <div class="df-row data-table">
      <span class="title">会员分类</span>

      <div class="chart-table">
        <div class="chart-box">
          <div class="chart-item">
            <div class="content">
              <span class="value">{{ kindGroup.Total }}</span>
              <div class="info">
                <span class="icon" :style="'background:' + colorList[0]"></span>
                <span class="name">全部</span>
              </div>
            </div>

            <div class="dfWebIcon dfWebIcon-dengyu"></div>
          </div>

          <div
            class="chart-item"
            v-for="(item, index) in kindGroup.Items"
            :key="index"
          >
            <div class="content">
              <span class="value">{{ item.Count }}</span>
              <div class="info">
                <span
                  class="icon"
                  :style="'background:' + colorList[index + 1]"
                ></span>
                <span class="name">{{ item.Label }}</span>
              </div>
            </div>

            <div class="dfWebIcon dfWebIcon-jia"></div>
          </div>
        </div>

        <div id="Kind" class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Member from "@/api/member.js";

export default {
  data() {
    return {
      submitData: {
        // 提交数据
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
      },
      stageGroup: {},
      lossGroup: {},
      kindGroup: {},
      multipleDate: [], // 日期选择器数据
      colorList: [
        "#39b54a",
        "#1cbbb4",
        "#0081ff",
        "#e54d42",
        "#f37b1d",
        "#fbbd08",
        "#8dc63f",
        "#6739b6",
        "#9c26b0 ",
        "#e03997  ",
        "#a5673f  ",
      ],
    };
  },

  mounted() {
    // this.multipleDate = [
    //   this.util.getNowMonthToday(),
    //   this.util.getNowMonthToday(),
    // ];
    this.getSummaryReport();
  },

  methods: {
    // 获取会员概要分析
    async getSummaryReport() {
      try {
        let { data } = await Member.getSummaryReport();
        this.stageGroup = data.StageGroup;
        this.lossGroup = data.LossGroup;
        this.kindGroup = data.KindGroup;
        await this.handleEchart();
      } catch (err) {
        console.log(err);
      }
    },

    handleEchart() {
      let { stageGroup, lossGroup, kindGroup, colorList } = this;

      let copyStage = [],
        copyLoss = [],
        copyKind = [],
        stageColor = colorList,
        lossColor = colorList,
        kindColor = colorList.slice(1);

      stageGroup.Items.forEach((item) => {
        copyStage.push({ name: item.Label, value: item.Count });
      });
      lossGroup.Items.forEach((item) => {
        copyLoss.push({ name: item.Label, value: item.Count });
      });
      kindGroup.Items.forEach((item) => {
        copyKind.push({ name: item.Label, value: item.Count });
      });

      const stageChart = this.$echarts.init(document.getElementById("Stage")),
        lossChart = this.$echarts.init(document.getElementById("Loss")),
        kindChart = this.$echarts.init(document.getElementById("Kind"));

      stageChart.setOption({
        series: [
          {
            type: "pie",
            color: stageColor,
            label: {
              show: false,
            },
            data: copyStage,
          },
        ],
      });
      lossChart.setOption({
        series: [
          {
            type: "pie",
            color: lossColor,
            label: {
              show: false,
            },
            data: copyLoss,
          },
        ],
      });
      kindChart.setOption({
        series: [
          {
            type: "pie",
            color: kindColor,
            label: {
              show: false,
            },
            data: copyKind,
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
.analysis {
  .data-table {
    .flex-col;
    align-items: unset;
    width: 1200px;
    margin-bottom: 80px;

    .title {
      color: #888;
      font-size: 22px;
      text-align: center;
      padding: 10px 0;
    }

    .chart-table {
      .flex-row;
      align-items: unset;
      height: 120px;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-sizing: border-box;

      .chart-box {
        .flex-row;
        flex: 1;
        padding: 0 10px;
        box-sizing: border-box;

        .chart-item {
          .flex-row;
          padding: 0 15px;
          box-sizing: border-box;

          .content {
            .flex-col;
            min-width: 40px;

            .value {
              width: 100%;
              box-sizing: border-box;
              font-size: 28px;
              font-weight: bolder;
            }

            .info {
              .flex-row;
              width: 100%;

              .icon {
                width: 10px;
                height: 10px;
                margin-right: 2px;
                border-radius: 50%;
                overflow: hidden;
              }

              .name {
                font-size: 14px;
                color: #999;
              }
            }
          }

          > .dfWebIcon {
            margin-left: 15px;
            color: #bbb;
          }

          &:last-child .dfWebIcon {
            display: none;
          }
        }
      }

      .chart {
        width: 120px;
        height: 120px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
